import "swiper/css";

import "swiper/css/navigation";

import "swiper/css/pagination";

import * as React from "react";

import { Navigation, Pagination } from "swiper/modules";

import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

function CustomNavigation() {
  const swiper = useSwiper();

  return (
    <>
      <button
        className="dark !absolute left-2 top-1/2 z-10 -translate-y-1/2"
        onClick={() => swiper.slidePrev()}
      >
        <MdOutlineNavigateBefore size="2.7em" fill="white" />
      </button>
      <button
        className="dark !absolute right-2 top-1/2 z-10 -translate-y-1/2"
        onClick={() => swiper.slideNext()}
      >
        <MdOutlineNavigateNext size="2.7em" fill="white" />
      </button>
    </>
  );
}

function customPagination(_, className) {
  return `<span class="${className} w-4 h-4 [&.swiper-pagination-bullet-active]:!opacity-100 [&.swiper-pagination-bullet-active]:[background:rgb(var(--color-background))] !opacity-50 ![background:rgb(var(--color-background))]"></span>`;
}

export default function Gallery({ data }) {
  return (
    <div className="max-w-screen-xl mx-auto mb-10">
      <div className="h-auto w-full max-w-full relative px-4">
        <Swiper
          loop={true}
          autoplay={{
            delay: 1,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
            stopOnLastSlide: false,
            waitForTransition: true,
          }}
          pagination={{
            enabled: true,

            clickable: true,

            dynamicBullets: true,

            renderBullet: customPagination,
          }}
          modules={[Navigation, Pagination]}
          className="relative  [&_div.swiper-button-next]:text-background [&_div.swiper-button-prev]:text-background "
        >
          {data.map((img, index) => (
            <SwiperSlide key={index} className="select-none">
              <img
                src={img.imgelink}
                alt={`image-${index}`}
                className="w-full h-full max-w-full"
              />
            </SwiperSlide>
          ))}

          <CustomNavigation />
        </Swiper>
      </div>
    </div>
  );
}
