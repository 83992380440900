import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./PartnersComponents.css";

const PartnersComponents = () => {
  const theme = useTheme();
  const sectionTitle = {
    fontFamily: "'Playfair Display',sans-serif",
    color: "black",
    paddingTop: "10px",

    paddingBottom: "10px",
  };
  return (
    <>
      <Grid
        className="partners-container-main"
        data-aos="fade-up"
        data-aos-duration="3000"
        container
        spacing={2}
        sx={{
          pl: "50px",
          pr: "50px",
          pb: "50px",

          pt: "50px",
          pb: "100px",
          backgroundImage: "url(/assets/Prtners.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid item xs={12} sm={8} sx={{ pb: "20px" }}>
          <Typography
            id="partnersection"
            style={sectionTitle}
            sx={{ fontFamily: "sectionTitleFont", color: "black" }}
          >
            PARTNERS
          </Typography>
          <Typography sx={{ fontFamily: "titleFont", color: "black" }}>
            <h2 className="titlepartners"> STRATEGIC DEVELOPMENT PARTNERS </h2>
          </Typography>
        </Grid>
        <Grid
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="50"
          className="partners-container"
        >
          <Grid item xs={12} sm={4} md={4} className="parners-logo-card">
            <img src="assets/ara.png" className="partners-logo" alt="img" />
          </Grid>
          <Grid item xs={10} md={8}>
            <p
              className="discription-pera partners-des-1"
              style={{ opacity: "1" }}
            >
              ARA Real Estate Development LLC is a development and Real Estate
              management arm of CORE International Holding and is currently
              overseeing the development of the Arabian Hills landmark project
              in the UAE.
            </p>
          </Grid>
        </Grid>
        <Grid
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="100"
          className="partners-container"
        >
          <Grid item xs={2} sm={4} md={4} className="parners-logo-card">
            <img src="assets/core.png" className="partners-logo" alt="img" />
          </Grid>
          <Grid item xs={10} md={8}>
            <p className="discription-pera partners-des">
              CORE International Holding is a leading private joint stock
              company with a diverse portfolio of investments across various
              industries with a total paid capital of AED 2.83 billion.CORE
              subsidiaries: Dar AL Marafah Investment LLC, owner of Al Ain
              University and Scifiniti Commercial Investment LLC, plays a
              significant role.Additionally, Iceberg Capital Limited in the ADGM
              and Actus Investment LLC are integral parts of CORE 's diverse
              investment portfolio.
            </p>
          </Grid>
        </Grid>
        <Grid
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="150"
          className="partners-container"
        >
          <Grid item xs={2} sm={4} md={4} className="parners-logo-card">
            <img src="assets/DECA.png" className="partners-logo" alt="img" />
          </Grid>
          <Grid item xs={10} md={8}>
            <p className="discription-pera partners-des">
              At DECA, as a premier development management company, we go beyond the ordinary—we bring visionary ideas to life. Guided by unwavering commitment and global expertise, we craft the future, merging innovation with impeccable execution
            </p>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PartnersComponents;
