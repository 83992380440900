import React from "react";
import { Typography, Grid, Link } from "@mui/material";
import "./Footer.css";

function Footer() {
  return (
    <footer>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 4 }}
      >
        <div className="main-container-footer">
          <div className="footer-menu-row ">
            <ul className="footer-menu">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/experience">EXPERIENCES</a>
              </li>
              <li>
                <a href="/partners">PARTNERS</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
          <div className="logo-row">
            <a href="/">
              <img src="assets/logo-black.png" className="footer-logo" />
            </a>
          </div>

          <div className="flex flex-col md:flex-row items-center justify-center gap-1">
            <ul>
              <li className="social-links-item flex items-center gap-2 mb-2">
                <img src="assets/mail-icon.svg" />
                <a href="mailto:info@deca-properties.com;">
                  info@deca-properties.com
                </a>
              </li>

              <li className="social-links-item flex items-center gap-2 mb-2">
                <img src="assets/phone-icon.svg" />
                <a href="tel:+971568686786">+971-5686 86 786</a>
              </li>
              <li className="social-links-item flex items-center gap-2 mb-2">
                <img src="assets/pin.svg" className=" w-5 h-5" />
                <a target="_blank" href="https://www.google.com/maps/place/Deca+Properties+SZR/@25.196391,55.263398,16z/data=!4m6!3m5!1s0x3e5f43ff023041ad:0x78c254353965df9f!8m2!3d25.1963907!4d55.263398!16s%2Fg%2F11lcydkky_?hl=en&entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D">
                  UTC B-14, Main Sheikh Zayed Road, Al Wasl, Dubai, UAE
                </a>
              </li>
            </ul>

            {/* <ul className="social-links">
              <li className="social-links-item flex items-center gap-2">
                <img src="assets/map.svg" className="socail-icons-footer" />
                <a href="#">
                  UTC B-14, Main Sheikh Zayed Road, Al Wasl, Dubai, UAE
                </a>
              </li>
            </ul> */}
          </div>
        </div>

        <div className="copyright-row">
          <div className="copyright-asha">
            <p>Copyright © 2024, DECA. All rights reserved.</p>
          </div>

          <div className="terms-row">
            <p > <a href="/terms-and-conditions">Terms and Conditions</a></p>
            <p > <a href="/privacy-policy">Privacy Policy</a></p>
            {/* <p style={{}}>Privacy Policy</p> */}
          </div>
        </div>
      </Grid>
    </footer>
  );
}

export default Footer;
