import React, { useEffect } from "react";
import "./LocationComponent.css";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AOS from "aos";
import "aos/dist/aos.css";
const LocationComponent = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const theme = useTheme();
  const sectionTitle = {
    fontFamily: "sectionTitleFont",
    color: "black",
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  return (
    <Grid
      className="location-main-section"
      data-aos="fade-up"
      data-aos-offset="500"
      data-aos-duration="1000"
      container
      spacing={2}
      sx={{
        pl: "50px",
        pr: "50px",
        pt: "50px",
        pb: "100px",
        backgroundColor: "#CBC5B3",
      }}
    >
      <Grid item xs={12} sm={8} md={12}>
        <Typography
          className="location-head"
          id="locationsection"
          style={sectionTitle}
          sx={{ fontFamily: "sectionTitleFont" }}
        >
          LOCATION
        </Typography>
        <Typography sx={{ fontFamily: "titleFont" }} className="location-sub">
          CLOSE TO EVERYTHING LIVELY AND SERENE
        </Typography>
        <Typography class="discription-pera" sx={{ fontFamily: "bodyFont" }}>
          Arabian Hills Estate is nestled in the heart of the UAE, within close
          proximity to some of the most prominent International Airports in the
          region, and right in the centre of some of the best experiences that
          Dubai has to offer.
        </Typography>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: "20px" }}
        >
          <Grid item xs={12}>
            <img
              className="rectangle-10"
              src="/assets/location.png"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        className="location-options"
        item
        xs={12}
        sm={4}
        sx={{ pt: "20px" }}
      >
        <Grid
          className="Content-bullets"
          container
          spacing={1}
          direction="column"
          sx={{ pt: "0px", pl: "0px" }}
        >
          <Grid item>
            <h2 className="icon-list-heading">UNDER 25 MINs</h2>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="50"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/location-icon-1.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Hot Air Balloon
                <br />
                <span className="mins-text">11 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="100"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/sky-dawing.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Skydive
                <br />
                <span className="mins-text">12 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="150"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/rugby-sevens.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Rugby Sevens
                <br />
                <span className="mins-text">20 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="200"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/dubai-mall.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Outlet Mall
                <br />
                <span className="mins-text">24 MINS</span>
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid
          className="Content-bullets"
          container
          spacing={1}
          direction="column"
          sx={{ pt: "0px", pl: "0px" }}
        >
          <Grid item>
            <h2 className="icon-list-heading">UNDER 40 MINs</h2>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="50"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/repton-school.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Repton School
                <br />
                <span className="mins-text">34 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="100"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/dubai-Creek-Harbour.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Dubai Creek Harbour
                <br />
                <span className="mins-text">36 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="150"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/latifa-hospital.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Latifa Hospital
                <br />
                <span className="mins-text">40 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="200"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/dubai-mall.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Dubai Mall
                <br />
                <span className="mins-text">40 MINS</span>
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid
          className="Content-bullets"
          container
          spacing={1}
          direction="column"
          sx={{ pt: "0px", pl: "0px" }}
        >
          <Grid item>
            <h2 className="icon-list-heading">UNDER 30 MINs</h2>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="50"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/zayed-University.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Zayed University
                <br />
                <span className="mins-text">27 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="100"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/academic-city.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Academic City
                <br />
                <span className="mins-text">27 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="150"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/university-of-Dubai.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                University of Dubai
                <br />
                <span className="mins-text">27 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="200"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/silicon-central-Mall.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Silicon Central Mall
                <br />
                <span className="mins-text">27 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="250"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/Silicon-Oasis.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Silicon Oasis
                <br />
                <span className="mins-text">27 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="300"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/District-11.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                District 11
                <br />
                <span className="mins-text">30 MINS</span>
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid
          className="Content-bullets"
          container
          spacing={1}
          direction="column"
          sx={{ pt: "0px", pl: "0px" }}
        >
          <Grid item>
            <h2 className="icon-list-heading">UNDER 50 MINs</h2>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="50"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/District-12.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                City Walk
                <br />
                <span className="mins-text">40 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="100"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/rashid-hospital.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Rashid Hospital
                <br />
                <span className="mins-text">42 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="150"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/dubai-intl-airport.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Dubai Intl’ Airport
                <br />
                <span className="mins-text">44 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="200"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/dubai-intl-airport.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Al Ain Intl’ Airport
                <br />
                <span className="mins-text">44 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="250"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/Nikki Beach.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                Nikki Beach
                <br />
                <span className="mins-text">45 MINS</span>
              </Typography>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              data-aos-delay="300"
              className="icon-list"
            >
              <img
                src="/assets/location-icons/Nikki Beach.svg"
                className="icon-list-img"
              />
              <Typography
                className="icon-list-title"
                sx={{ fontFamily: "bodyFont", textAlign: "left" }}
              >
                La Mer Beach
                <br />
                <span className="mins-text">47 MINS</span>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LocationComponent;
