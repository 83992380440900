import React, { useRef, useState } from "react";
import { Grid, Typography, TextField, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import emailjs from "@emailjs/browser";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

const ContactComponent = () => {
  const formRef = useRef();
  // const theme = useTheme();
  const sectionTitle = {
    fontFamily: "sectionTitleFont",
    color: "white",
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "service_otx1rwi",
        "template_1wivaxh",
        formRef.current,
        "DsWeIlaGUWjO8AFSt"
      )
      .then((res) => {
        console.log(res.text);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Grid
      className="contact-container-main-section"
      data-aos="fade-up"
      data-aos-duration="3000"
      container
      spacing={2}
      sx={{
        pl: "50px",
        pr: "50px",
        pt: "250px",
        pb: "100px",
        backgroundImage: "url(/assets/contact.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Grid
        data-aos="fade-right"
        data-aos-duration="900"
        data-aos-offset="300"
        data-aos-delay="100"
        item
        xs={12}
        sm={8}
        sx={{ pb: "20px" }}
      >
        <Typography
          className="location-head"
          id="contactsection"
          style={sectionTitle}
          sx={{ fontFamily: "sectionTitleFont", color: "white!important" }}
        >
          CONTACT
        </Typography>
        <Typography
          className="location-sub"
          sx={{ fontFamily: "titleFont", color: "white!important" }}
        >
          LEARN MORE ABOUT ARABIAN HILLS ESTATE
        </Typography>
        <Typography
          className="discription-pera"
          sx={{ fontFamily: "bodyFont", color: "white!important" }}
        >
          We are happy to connect with you. Reach out to the right team so we
          can serve you better.
        </Typography>
      </Grid>
      <Grid
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-offset="300"
        data-aos-delay="200"
        item
        xs={12}
        sm={8}
        md={8}
        sx={{
          textAlign: "center",
          pt: "20px",
          color: "white",
          borderColor: "white",
        }}
      >
        <form ref={formRef} onSubmit={sendEmail}>
          <Grid className="contact-row-ipad" container spacing={2}>
            <Grid item sx={8} xs={12} sm={10} className={"input-field"}>
              <TextField
                fullWidth
                label="Name"
                id="name"
                variant="outlined"
                InputProps={{
                  style: {
                    color: "white",
                    background: "rgba(255, 255, 255, 0.2)",
                    border: "solid",
                    borderWidth: "0px",
                    borderColor: "white",
                    borderRadius: "0",
                    "&:focus": { borderColor: "none", boxShadow: "none" },
                  },
                }}
                InputLabelProps={{
                  style: { color: "rgba(255, 255, 255, 0.5)" },
                }}
                type="text"
                name="your_name"
                placeholder="Your Name"
                required
              />
            </Grid>
            {/* <Grid item xs={12} sm={5}>
              <TextField
                fullWidth
                label="Subject"
                id="subject"
                variant="outlined"
                InputProps={{
                  style: {
                    color: "white",
                    background: "rgba(255, 255, 255, 0.2)",
                    border: "solid",
                    borderWidth: "0px",
                    borderColor: "white",
                    borderRadius: "0",
                    "&:focus": { borderColor: "none", boxShadow: "none" },
                  },
                }}
                InputLabelProps={{
                  style: { color: "rgba(255, 255, 255, 0.5)" },
                }}
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
              />
            </Grid> */}
            <Grid item xs={12} sm={10}>
              <TextField
                fullWidth
                label="Email Address"
                id="email"
                variant="outlined"
                InputProps={{
                  style: {
                    color: "white",
                    background: "rgba(255, 255, 255, 0.2)",
                    border: "solid",
                    borderWidth: "0px",
                    borderColor: "white",
                    borderRadius: "0",
                    "&:focus": { borderColor: "none", boxShadow: "none" },
                  },
                }}
                InputLabelProps={{
                  style: { color: "rgba(255, 255, 255, 0.5)" },
                }}
                type="email"
                name="your_email"
                placeholder="Your Email"
                required
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                fullWidth
                label="Contact No"
                id="phone"
                variant="outlined"
                InputProps={{
                  style: {
                    color: "white",
                    background: "rgba(255, 255, 255, 0.2)",
                    border: "solid",
                    borderWidth: "0px",
                    borderColor: "white",
                    borderRadius: "0",
                    "&:focus": { borderColor: "none", boxShadow: "none" },
                  },
                }}
                InputLabelProps={{
                  style: { color: "rgba(255, 255, 255, 0.5)" },
                }}
                type="text"
                name="your_number"
                placeholder="Phone Number"
                required
              />
            </Grid>
            {/* <Grid item xs={12} sm={10}>
              <TextField
                className="customGridHeight"
                fullWidth
                label="Type your enquiries here.."
                id="message"
                variant="outlined"
                InputProps={{
                  style: {
                    color: "white",
                    background: "rgba(255, 255, 255, 0.2)",
                    border: "solid",
                    borderWidth: "0px",
                    borderRadius: "0",
                    borderColor: "white",
                    fontWeight: "200",
                    "&:focus": { borderColor: "none", boxShadow: "none" },
                    paddingTop: "40px",
                  },
                }}
                InputLabelProps={{
                  style: { color: "rgba(255, 255, 255, 0.5)" },
                }}
                name="message"
                value={formData.message}
                onChange={handleInputChange}
              />
            </Grid> */}
            <Grid item xs={12} sm={10}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: "transparent",
                  color: "white",
                  background: "rgba(255, 255, 255, 0.2)",
                  border: "solid",
                  borderWidth: "0.5px",
                  borderColor: "white",
                  borderRadius: "0",
                  marginBottom: "30px",
                  "&:hover": { backgroundColor: "#fff", color: "black" },
                }}
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-offset="300"
        data-aos-delay="200"
        item
        xs={4}
        className="customGridPadding"
      >
        <Grid
          container
          direction="column"
          spacing={4}
          className="contact-text-container"
        >
          <Grid item className="contact-text-container-2">
            <Typography
              className="contact-icons"
              sx={{
                fontFamily: "bodyFont",
                color: "white",
                mb: 2,
                maxWidth: "425px",
                className: "form-info-text",
                // Limit max width to 425px
              }}
            >
              <EmailOutlinedIcon
                className="icon-socails"
                sx={{ marginRight: 1, fontSize: "20px" }}
              />
              <a href="mailto:info@deca-properties.com;">
                <span>info@deca-properties.com</span>
              </a>
            </Typography>
            <Typography
              className="contact-icons"
              sx={{
                fontFamily: "bodyFont",
                color: "white",
                mb: 2,
                maxWidth: "425px",
                className: "form-info-text", // Limit max width to 425px
              }}
            >
              <PhoneOutlinedIcon
                className="icon-socails"
                sx={{ marginRight: 1, fontSize: "20px" }}
              />
              <a href="tel:+971568686786">
                <span>+971-5686 86 786</span>
              </a>
            </Typography>
            <Typography
              className="contact-icons"
              sx={{
                fontFamily: "bodyFont",
                color: "white",
                mb: 2,
                maxWidth: "425px",
                className: "form-info-text", // Limit max width to 425px
              }}
            >
              <LocationOnOutlinedIcon
                className="icon-socails"
                sx={{ marginRight: 1, fontSize: "20px" }}
              />
              <a
                target="_blank"
                href="https://www.google.com/maps/place/Deca+Properties+SZR/@25.196391,55.263398,16z/data=!4m6!3m5!1s0x3e5f43ff023041ad:0x78c254353965df9f!8m2!3d25.1963907!4d55.263398!16s%2Fg%2F11lcydkky_?hl=en&entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D"
              >
                <span>
                  UTC B-14, Main Sheikh Zayed Road, Al Wasl, Dubai, UAE
                </span>
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactComponent;
