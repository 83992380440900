import React from 'react';
import Footer from './Footer';
import SecoundHeaderComponents from './SecoundHeaderComponents';

const TermsAndConditions = () => {
    return (
        <>
            <SecoundHeaderComponents />

            <div className="w-screen mx-auto px-4 py-8" style={{
                backgroundImage: "url(/assets/Ameneties-estaste.png)",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}>
                <div className=" max-w-screen-2xl mx-auto mt-12">
                    <h1 className="text-black text-4xl text-center px-4 uppercase font-play-fair mb-4">Terms and Conditions</h1>
                    <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black">Terms and Conditions for Arabian Hills</h2>
                    <p className="discription-pera">Effective as of January 2024</p>
                    <div className="prose lg:prose-xl text-gray-700">
                        <p className='discription-pera '>
                            Welcome to Arabian Hills! These terms and conditions outline the rules and regulations for the use of Arabian Hills’ Website, located at <a href="https://arabianhills.com" className="text-blue-600">arabianhills.com</a>. By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use arabianhills.com if you do not agree to all of the terms and conditions stated on this page.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">1. The following terminology applies to these Terms and Conditions:</h2>
                        <p className='discription-pera'>
                            “Client”, “You” and “Your” refers to you, the person accessing this website. “The Company”, “Ourselves”, “We”, “Our”, and “Us”, refers to Arabian Hills. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">2. License</h2>
                        <p className='discription-pera'>
                            Unless otherwise stated, Arabian Hills and/or its licensors own the intellectual property rights for all material on arabianhills.com. All intellectual property rights are reserved. You may view and/or print pages from arabianhills.com for your own personal use subject to restrictions set in these terms and conditions.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">3. User Comments</h2>
                        <p className='discription-pera'>
                            You may post comments as long as they are not obscene, illegal, defamatory, threatening, infringing on intellectual property rights, invasive of privacy, or otherwise injurious to third parties. Comments should not solicit or promote business or custom or present commercial activities or unlawful activity.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">4. Hyperlinking to our Content</h2>
                        <p className='discription-pera'>
                            Government agencies; Search engines; News organizations; Online directory distributors may link to our Website. These organizations may link to our home page, to publications, or to other website information so long as the link:
                        </p>
                        <ul className="list-disc pl-6">
                            <li className='discription-pera'>is not in any way misleading;</li>
                            <li className='discription-pera'>does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services;</li>
                            <li className='discription-pera'>fits within the context of the linking party’s site.</li>
                        </ul>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">5. iFrames</h2>
                        <p className='discription-pera'>
                            Without prior approval and express written permission, you may not create frames around our Web pages.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">6. Reservation of Rights</h2>
                        <p className='discription-pera'>
                            We reserve the right at any time and in our sole discretion to request that you remove all links or any particular link to our website.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">7. Removal of links from our website</h2>
                        <p className='discription-pera'>
                            If you find any link on our website or any linked website objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">8. Content Liability</h2>
                        <p className='discription-pera'>
                            We shall have no responsibility or liability for any content appearing on your website. You agree to indemnify and defend us against all claims arising out of or based upon your website.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">9. Disclaimer</h2>
                        <p className='discription-pera'>
                            To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose, and/or the use of reasonable care and skill). Nothing in this disclaimer will:
                        </p>
                        <ul className="list-disc pl-6">
                            <li className='discription-pera'>Limit or exclude our or your liability for death or personal injury resulting from negligence;</li>
                            <li className='discription-pera'>Limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                            <li className='discription-pera'>Limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                            <li className='discription-pera'>Exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                        </ul>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">10. Contact Information</h2>
                        <p className='discription-pera'>
                            If you have any inquiries about these Terms and Conditions, please contact us:
                        </p>
                        <p className='discription-pera'>By phone: +971 5686 86 786</p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
        // <div>jidfashidfhsd</div>
    );
};

export default TermsAndConditions;
