import React from "react";
import "./ClustersComponent.css";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ClustersComponent = () => {
  const theme = useTheme();
  const sectionTitle = {
    fontFamily: "sectionTitleFont",
    color: "white",
    paddingTop: "10px",
    paddingBottom: "10px",
  };
  const boxStyle = {
    padding: theme.spacing(2), // Responsive padding
    textAlign: "center",
    borderRadius: theme.spacing(1), // Responsive border-radius
    border: `1px solid #CBC5B3`,
    height: "60px",
  };
  return (
    <Grid
      data-aos="fade-up"
      data-aos-duration="3000"
      className="clustor-container"
      container
      spacing={2}
      sx={{
        pl: "50px",
        pr: "50px",
        pt: "50px",
        pb: "100px",
        backgroundColor: "#313B2F",
        backgroundImage: "url(/assets/clustor-bg.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Grid
        data-aos="fade-right"
        data-aos-duration="900"
        data-aos-offset="300"
        data-aos-delay="100"
        item
        xs={12}
        sm={8}
      >
        <Typography
          id="clusterssection"
          style={sectionTitle}
          className="location-head"
          sx={{
            fontFamily: "sectionTitleFont",
            pb: "0px!important",
            color: "#CBC5B3!important",
          }}
        >
          CLUSTERS
        </Typography>
        <Typography
          sx={{ fontFamily: "titleFont", color: "white!important" }}
          className="location-sub"
        >
          THE MASTER PLAN
        </Typography>
        <Typography
          class="discription-pera-main"
          sx={{ fontFamily: "bodyFont", color: "white !important" }}
        >
          A master community crafted for the discerning few, Arabian Hills
          Estate is a metropolis of infinite work, play, and leisure.
        </Typography>
      </Grid>
      <Grid
        data-aos="fade-right"
        data-aos-duration="900"
        data-aos-offset="300"
        data-aos-delay="300"
        item
        xs={12}
        sm={6}
        md={6}
        sx={{ textAlign: "center", pt: "20px", pr: "50px" }}
      >
        <img
          className="rectangle-8"
          src="/assets/master-plan.png"
          style={{ maxWidth: "100%!important", height: "auto" }}
        />
      </Grid>
      {/* First column with two rows */}
      <Grid item xs={12} sm={6}>
        <Grid
          className="clustor-boxes-section-main"
          container
          direction="column"
          spacing={2}
        >
          <Grid
            data-aos="fade-left"
            data-aos-duration="900"
            data-aos-offset="300"
            data-aos-delay="200"
            item
          >
            <Typography
              className="location-sub"
              style={sectionTitle}
              sx={{
                fontFamily: "sectionTitleFont",
                pb: "10px!important",
                color: "white!important",
              }}
            >
              ARABIAN HILLS ESTATE
            </Typography>
            <Grid className="clustor-main-div" container spacing={2}>
              <Grid className="clustor-box-row" item xs={12} sm={6}>
                <div className="flex flex-col border border-[#cbc5b3] rounded-lg p-6">
                  <Typography
                    className="clustor-box-title"
                    sx={{ fontFamily: "titleFont", color: "white" }}
                  >
                    VILLA PLOTS
                  </Typography>
                  <Typography
                    className="clustor-box-sub"
                    sx={{ fontFamily: "bodyFont", color: "white" }}
                  >
                    12,000 sq.ft to 20,000 sq.ft
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="flex flex-col border border-[#cbc5b3] rounded-lg p-6">
                  <Typography
                    className="clustor-box-title"
                    sx={{ fontFamily: "titleFont", color: "white" }}
                  >
                    MANSION PLOTS
                  </Typography>
                  <Typography
                    className="clustor-box-sub"
                    sx={{ fontFamily: "bodyFont", color: "white" }}
                  >
                    20,000 sq.ft to 50,000 sq.ft
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="flex flex-col border border-[#cbc5b3] rounded-lg p-6">
                  <Typography
                    className="clustor-box-title"
                    sx={{ fontFamily: "titleFont", color: "white" }}
                  >
                    MEGA MANSION PLOTS
                  </Typography>
                  <Typography
                    className="clustor-box-sub"
                    sx={{ fontFamily: "bodyFont", color: "white" }}
                  >
                    50,000 sq.ft to 76,000 sq.ft
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="flex flex-col border border-[#cbc5b3] rounded-lg p-6">
                  <Typography
                    className="clustor-box-title"
                    sx={{ fontFamily: "titleFont", color: "white" }}
                  >
                    ESTATE PLOTS
                  </Typography>
                  <Typography
                    className="clustor-box-sub"
                    sx={{ fontFamily: "bodyFont", color: "white" }}
                  >
                    76,000 sq.ft to 132,000 sq.ft
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          data-aos="fade-left"
          data-aos-duration="900"
          data-aos-offset="300"
          data-aos-delay="100"
          container
          spacing={2}
          className="clustor-box-sub"
          sx={{ fontFamily: "bodyFont", color: "white" }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            className="clustor-box-sub "
            sx={{ fontFamily: "bodyFont", color: "white" }}
          >
            <div className="flex flex-col mt-6">
              <h6 className="clustor-title pb-4">OUTDOOR AMENITIES</h6>
              <ul className="clustor-list">
                <li
                  className="clustor-box-sub"
                  sx={{ fontFamily: "bodyFont", color: "white" }}
                >
                  <img src="assets/ghaf-park.svg" className="icon-clustor" />
                  Ghaf Park
                </li>
                <li
                  className="clustor-box-sub"
                  sx={{ fontFamily: "bodyFont", color: "white" }}
                >
                  <img
                    src="assets/community-parks.svg"
                    className="icon-clustor"
                  />
                  Community Parks
                </li>
                <li
                  className="clustor-box-sub"
                  sx={{ fontFamily: "bodyFont", color: "white" }}
                >
                  <img
                    src="assets/walking-tracks.svg"
                    className="icon-clustor"
                  />
                  Walking Tracks
                </li>
                <li
                  className="clustor-box-sub"
                  sx={{ fontFamily: "bodyFont", color: "white" }}
                >
                  <img src="assets/zen-gardens.svg" className="icon-clustor" />
                  Zen Gardens
                </li>
                <li
                  className="clustor-box-sub"
                  sx={{ fontFamily: "bodyFont", color: "white" }}
                >
                  <img
                    src="assets/outdoor-cinema.svg"
                    className="icon-clustor"
                  />
                  Outdoor Cinema
                </li>
                <li
                  className="clustor-box-sub"
                  sx={{ fontFamily: "bodyFont", color: "white" }}
                >
                  <img src="assets/lakes.svg" className="icon-clustor" />
                  Lakes
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item x={12} sm={6}>
            <div className="flex flex-col mt-6">
              <h6 className="clustor-title pb-4">INDOOR AMENITIES</h6>
              <ul className="clustor-list">
                <li
                  className="clustor-box-sub"
                  sx={{ fontFamily: "bodyFont", color: "white" }}
                >
                  <img src="assets/schools.svg" className="icon-clustor" />
                  Schools
                </li>
                <li
                  className="clustor-box-sub"
                  sx={{ fontFamily: "bodyFont", color: "white" }}
                >
                  <img src="assets/mosques.svg" className="icon-clustor" />
                  Mosques
                </li>
                <li
                  className="clustor-box-sub"
                  sx={{ fontFamily: "bodyFont", color: "white" }}
                >
                  <img src="assets/clinics.svg" className="icon-clustor" />
                  Clinics
                </li>
                <li
                  className="clustor-box-sub"
                  sx={{ fontFamily: "bodyFont", color: "white" }}
                >
                  <img
                    src="assets/retails-outlets.svg"
                    className="icon-clustor"
                  />
                  Retail Outlets
                </li>
                <li
                  className="clustor-box-sub"
                  sx={{ fontFamily: "bodyFont", color: "white" }}
                >
                  <img
                    src="assets/petrol-station.svg"
                    className="icon-clustor"
                  />
                  Petrol Station
                </li>
                <li
                  className="clustor-box-sub"
                  sx={{ fontFamily: "bodyFont", color: "white" }}
                >
                  <img
                    src="assets/community-centre.svg"
                    className="icon-clustor"
                  />
                  Community Centres
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClustersComponent;
