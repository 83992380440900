import React from 'react';
import Footer from './Footer';
import SecoundHeaderComponents from './SecoundHeaderComponents';

const PrivacyPolicy = () => {
    return (
        <>
            <SecoundHeaderComponents />

            <div
                className="w-screen mx-auto px-4 py-8"
                style={{
                    backgroundImage: "url(/assets/Ameneties-estaste.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <div className="max-w-screen-2xl mx-auto mt-12">
                    <h1 className="text-black text-4xl text-center px-4 uppercase font-play-fair mb-4">
                        Privacy Policy
                    </h1>
                    <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black">
                        Privacy Policy for Arabian Hills
                    </h2>
                    <p className="discription-pera">Effective as of January 2024</p>
                    <div className="prose lg:prose-xl text-gray-700">
                        <p className="discription-pera">
                            Welcome to Arabian Hills. Your privacy is critically important to us. Arabian Hills, located at UTC-B14, Sheikh Zayed Road – Al Wasl, Dubai, UAE, operates the website{' '}
                            <a href="https://arabianhills.com" className="text-blue-600">
                                arabianhills.com
                            </a>. This privacy policy page is to inform you about our policies regarding the collection, use, and disclosure of personal data when you use our website and the choices you have associated with that data.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">
                            1. Information Collection and Use
                        </h2>
                        <p className="discription-pera">
                            We collect several different types of information for various purposes to provide and improve our service to you. This may include, but is not limited to, personal data such as email addresses, phone numbers, and usage data.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">
                            2. Data Use
                        </h2>
                        <p className="discription-pera">
                            We use the collected data for various purposes:
                        </p>
                        <ul className="list-disc pl-6">
                            <li className="discription-pera">To provide and maintain our service</li>
                            <li className="discription-pera">To notify you about changes to our service</li>
                            <li className="discription-pera">To allow you to participate in interactive features of our service when you choose to do so</li>
                            <li className="discription-pera">To provide customer care and support</li>
                            <li className="discription-pera">To provide analysis or valuable information so that we can improve the service</li>
                            <li className="discription-pera">To monitor the usage of the service</li>
                            <li className="discription-pera">To detect, prevent, and address technical issues</li>
                        </ul>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">
                            3. Data Transfer
                        </h2>
                        <p className="discription-pera">
                            Your information, including personal data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">
                            4. Disclosure of Data
                        </h2>
                        <p className="discription-pera">
                            We may disclose your personal data in the good faith belief that such action is necessary to:
                        </p>
                        <ul className="list-disc pl-6">
                            <li className="discription-pera">Comply with a legal obligation</li>
                            <li className="discription-pera">Protect and defend the rights or property of Arabian Hills</li>
                            <li className="discription-pera">Prevent or investigate possible wrongdoing in connection with the service</li>
                            <li className="discription-pera">Protect the personal safety of users of the service or the public</li>
                            <li className="discription-pera">Protect against legal liability</li>
                        </ul>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">
                            5. Security of Data
                        </h2>
                        <p className="discription-pera">
                            The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">
                            6. Service Providers
                        </h2>
                        <p className="discription-pera">
                            We may employ third-party companies and individuals to facilitate our service, to provide the service on our behalf, to perform service-related services, or to assist us in analyzing how our service is used.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">
                            7. Links to Other Sites
                        </h2>
                        <p className="discription-pera">
                            Our service may contain links to other sites. If you click on a third-party link, you will be directed to that site. We strongly advise you to review the privacy policy of every site you visit.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">
                            8. Changes to This Privacy Policy
                        </h2>
                        <p className="discription-pera">
                            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                        </p>

                        <h2 className="text-xl font-medium lg:text-2xl font-play-fair uppercase pb-3 text-black mt-6">
                            9. Contact Us
                        </h2>
                        <p className="discription-pera">
                            If you have any questions about this Privacy Policy, please contact us:
                        </p>
                        <p className="discription-pera">By phone: +971 56 8686 786</p>
                    </div>
                </div>
            </div>

            <Footer />
        </>

    );
};

export default PrivacyPolicy;
