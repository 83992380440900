import React from "react";
import Footer from "./Footer";
import SecoundHeaderComponents from "./SecoundHeaderComponents";
import PhaseTwoComponent from "./PhaseTwoComponent";
const PhaseTwo = () => {
    return (
        <div className="section-padding">
            <SecoundHeaderComponents />
            <PhaseTwoComponent />
            <Footer />
        </div>
    );
};

export default PhaseTwo;
