import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./AmenitiesComponents.css";
// import NewheaderCompontes from "./NewheaderCompontes";

const AmenitiesComponents = () => {
  const theme = useTheme();
  const sectionTitle = {
    fontFamily: "sectionTitleFont",
    color: "black",
    paddingTop: "0px!important",
    paddingBottom: "0px!important",
  };

  return (
    <>
      <Grid
        className="amenities-main-section"
        container
        spacing={2}
        sx={{
          pl: "50px",
          pt: "50px",
          pb: "50px",
          backgroundColor: "#ffffff",
          backgroundImage: "url(/assets/Ameneties-new.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid
          className="experience-container-main"
          data-aos="fade-up"
          data-aos-duration="3000"
          item
          xs={10}
          sm={8}
        >
          <Typography
            className="location-head aminites"
            id="estatesection"
            style={sectionTitle}
            sx={{ fontFamily: "sectionTitleFont" }}
          >
            EXPERIENCES
          </Typography>
          <Typography sx={{ fontFamily: "titleFont", pt: "0px!important" }}>
            <h2 className="location-sub"> THE AMENITIES </h2>
          </Typography>
          <Typography
            class="discription-pera"
            sx={{ fontFamily: "bodyFont", pb: "10px" }}
          >
            Discover an exciting neighbourhood with easy access to the city,
            while serene surroundings offer world - class amenities in an active
            community.
          </Typography>
          <div className="main-container-aminities">
            <div
              className="aminities-container"
              style={{
                display: "flex", // Use Flexbox
                justifyContent: "space-between", // Evenly distribute items along the row
                alignItems: "center", // Center items vertically

                marginTop: "30px",
                marginBottom: "30px",
                borderRadius: "8px",
              }}
            >
              <div
                data-aos="fade-up"
                data-aos-duration="3000"
                data-aos-delay="200"
                className="card-section"
                style={{ flex: "1", textAlign: "center" }}
              >
                <h4 className="card-title"> Well - being </h4>
                <div className="card-aminities">
                  <img src="assets/5star.svg" alt="Logo 2" />
                  <Typography variant="body2" className=" capitalize">
                    5 - Star Hotel Resorts & Spa
                  </Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/fitnesscenter.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize"> Fitness center </Typography>
                </div>
                <div className="card-aminities">
                  <img src="assets/amenities-icons/variant3.svg" alt="Logo 2" />
                  <Typography variant="body2" className=" capitalize"> Wellness Center </Typography>
                </div>
                <div className="card-aminities">
                  <img src="assets/amenities-icons/variant4.svg" alt="Logo 2" />
                  <Typography variant="body2" className=" capitalize">Co - working Spaces</Typography>
                </div>
                <div className="card-aminities">
                  <img src="assets/amenities-icons/variant5.svg" alt="Logo 2" />
                  <Typography variant="body2" className=" capitalize"> 3 Shopping Malls </Typography>
                </div>
                <div className="card-aminities">
                  <img src="assets/amenities-icons/variant6.svg" alt="Logo 2" />
                  <Typography variant="body2" className=" capitalize">Meditation Pavilion</Typography>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="3000"
                data-aos-delay="400"
                className="card-section secound-box"
                style={{ flex: "1", textAlign: "center" }}
              >
                <h4 className="card-title"> Sports </h4>
                <div className="card-aminities">
                  <img src="assets/amenities-icons/variant7.svg" alt="Logo 2" />
                  <Typography variant="body2" className=" capitalize">Cycle - run track</Typography>
                </div>
                <div className="card-aminities">
                  <img src="assets/amenities-icons/variant8.svg" alt="Logo 2" />
                  <Typography variant="body2" className=" capitalize"> Ball courts </Typography>
                </div>
                <div className="card-aminities">
                  <img src="assets/amenities-icons/variant9.svg" alt="Logo 2" />
                  <Typography variant="body2" className=" capitalize"> Padel tennis </Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant10.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize"> Outdoor gym </Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant11.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize"> Rock climbing </Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant12.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize"> Mini - golf </Typography>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="3000"
                data-aos-delay="600"
                className="card-section third-box"
                style={{ flex: "1", textAlign: "center" }}
              >
                <h4 className="card-title"> Services </h4>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant13.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize"> Carwash </Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant14.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize"> Shuttle bus </Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant15.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize"> Concierge </Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant16.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize"> Housekeeping </Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant17.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize"> Maintenance </Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant18.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize"> Childcare </Typography>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="3000"
                data-aos-delay="800"
                className="card-section four-box"
                style={{
                  flex: "1",
                  textAlign: "center",
                  background: "#838982!important",
                  backgroundColor: "#838982!important",
                }}
              >
                <h4 className="card-title"> SUSTAINABILITY </h4>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant19.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize">Water conservation</Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant20.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize">Wildlife conservation</Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant21.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize">Natural landscaping</Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant22.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize">EV charging ports</Typography>
                </div>
                <div className="card-aminities">
                  <img src="assets/amenities-icons/waste.svg" alt="Logo 2" />
                  <Typography variant="body2" className=" capitalize"> Smart waste </Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant24.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize"> Solar lighting </Typography>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="3000"
                data-aos-delay="1000"
                className="card-section five-box"
                style={{
                  flex: "1",
                  textAlign: "center",
                  backgroundColor: "#838982!important",
                }}
              >
                <h4 className="card-title"> Experiences </h4>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/variant25.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize">
                    Indoor / outdoor cinema
                  </Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/fitnesscenter.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize"> Adventure parks </Typography>
                </div>
                <div className="card-aminities">
                  <img src="assets/amenities-icons/compus.svg" alt="Logo 2" />
                  <Typography variant="body2" className=" capitalize"> Wild adventures </Typography>
                </div>
                <div className="card-aminities">
                  <img src="assets/amenities-icons/gitar.svg" alt="Logo 2" />
                  <Typography variant="body2" className=" capitalize"> Concerts </Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/skydiving.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize"> Skydiving </Typography>
                </div>
                <div className="card-aminities">
                  <img
                    src="assets/amenities-icons/hot-air-ballons.svg"
                    alt="Logo 2"
                  />
                  <Typography variant="body2" className=" capitalize"> Hot Air Balloon </Typography>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AmenitiesComponents;
