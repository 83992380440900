import React, { useState, useEffect, useRef } from "react";

import emailjs from "@emailjs/browser";
import line from "../assets/line.svg";
import villa from "../assets/plot1.jpeg";
import mansion from "../assets/plot2.jpg";
import megaMansion from "../assets/plot3.png";
import masterPlan from "../assets/MASTER PLAN (1) (2).jpg";
import villaDesign from "../assets/villa-design.jpg";
import one from "../assets/1.jpg";
import two from "../assets/2.jpg";
import three from "../assets/3.jpg";
import theConcept from "../assets/The concept Logo black.png";
import theConceptWhite from "../assets/The concept Logo White.png";
// import brochure from "../assets/AHE-BROCHURE.pdf";
import plot1 from "../assets/Plot/1/1.png";
import design11 from "../assets/Plot/1/design1.png";
import design12 from "../assets/Plot/1/design2.png";
import plot2 from "../assets/Plot/2/2.png";
import design21 from "../assets/Plot/1/design1.png";
import design22 from "../assets/Plot/1/design2.png";
import plot3 from "../assets/Plot/3/3.png";
import design31 from "../assets/Plot/1/design1.png";
import design32 from "../assets/Plot/1/design2.png";
import plot4 from "../assets/Plot/4/4.png";
import design41 from "../assets/Plot/1/design1.png";
import design42 from "../assets/Plot/1/design2.png";
import plot5 from "../assets/Plot/5/5.jpg";
import design51 from "../assets/Plot/1/design1.png";
import design52 from "../assets/Plot/1/design2.png";
import plot6 from "../assets/Plot/6/6.png";
import design61 from "../assets/Plot/1/design1.png";
import design62 from "../assets/Plot/1/design2.png";
import plot7 from "../assets/Plot/7/7.png";
import design71 from "../assets/Plot/1/design1.png";
import design72 from "../assets/Plot/1/design2.png";
import plot8 from "../assets/Plot/8/8.png";
import design81 from "../assets/Plot/1/design1.png";
import design82 from "../assets/Plot/1/design2.png";

import image1 from "../assets/Gallery/1.jpg";
import image2 from "../assets/Gallery/2.jpg";
import image3 from "../assets/Gallery/3.jpg";
import image4 from "../assets/Gallery/4.jpg";
import image5 from "../assets/Gallery/5.jpg";
import image6 from "../assets/Gallery/6.jpg";
import image7 from "../assets/Gallery/7.jpg";
import image8 from "../assets/Gallery/8.jpg";
import popUp from "../assets/popup.jpg";

import { FaLocationDot } from "react-icons/fa6";
import "./PhaseTwoComponent.css";
import Gallery from "./Galley";
import { RxCross1 } from "react-icons/rx";
import { Slide } from "react-awesome-reveal";
import LandingVideoComponent from "./LandingVideoComponent";

const PhaseTwoComponent = () => {
  const formRef = useRef();
  const [isPlotOpen, setIsPlotOpen] = useState(false);
  const [selectedPlot, setSelectedPlot] = useState(null);

  const handleCardClick = (plot) => {
    setSelectedPlot(plot);
    setIsPlotOpen(true);
  };
  const event_schedule = [
    { event: "on booking", time: "10%" },
    { event: "Host Welcome & Introduction", time: "9:00 PM" },
    { event: "Special Recognition, Arshad Nadeem", time: "9:00 PM" },
    { event: "Award Ceremony", time: "9:30 PM" },
    { event: "Audience Engagement", time: "10:30 PM" },
    { event: "Performance By Yashal Shahid", time: "10:45 PM" },
    { event: "Ali Azmat Concert", time: "11:15 PM" },
  ];
  const data = [
    {
      imgelink: image1,
    },
    {
      imgelink: image2,
    },
    {
      imgelink: image3,
    },
    {
      imgelink: image4,
    },
    {
      imgelink: image5,
    },
    {
      imgelink: image6,
    },
    {
      imgelink: image7,
    },
    {
      imgelink: image8,
    },
  ];

  const PlotData = [
    {
      name: "Andalusia",
      imgelink: plot1,
      designOne: design11,
      designTwo: design12,
      details:
        "A celebration of modern classic design inspired by Renaissance villas, Andalusia integrates lush gardens and resort-style luxury. The grand entry plaza, polished marble finishes, and tall carved wooden doors set the tone for an estate that exudes timeless sophistication. This villa is designed to bring nature into everyday living, with expansive terraces, private courtyards, and seamless indoor-outdoor transitions.",
    },
    {
      name: "Aurora",
      imgelink: plot2,
      designOne: design21,
      designTwo: design22,
      details:
        "Aurora reflects contemporary luxury with its serene lakeside placement and bright, open spaces. The gleaming white marble floors and floor-to-ceiling windows invite natural light and panoramic views into every corner. The villa’s design seamlessly integrates modern elegance with the tranquility of its surroundings, featuring terraces, roof gardens, and a pristine infinity pool.",
    },
    {
      name: "Casa del Sol",
      imgelink: plot3,
      designOne: design31,
      designTwo: design32,
      details:
        "Designed for a modern lifestyle that embraces nature, Casa del Sol is surrounded by palm trees and a pristine nature reserve. The villa offers a harmonious blend of vibrant outdoor spaces, such as an infinity pool with breathtaking reserve views, and luxurious interiors. Its use of chiselled Portland stone and large windows captures the essence of light, warmth, and exclusivity.",
    },
    {
      name: "Hacienda",
      imgelink: plot4,
      designOne: design41,
      designTwo: design42,
      details:
        "Hacienda pays homage to Spanish-inspired design with its terracotta sloping roofs, stucco walls, and vibrant central courtyard. Shady trees, reflective water features, and flowering Frangipani trees create a warm and inviting ambiance. The villa balances classic architecture with modern luxury, featuring spacious terraces and grand living areas designed for tranquility and comfort.",
    },
    {
      name: "Villa Alegría",
      imgelink: plot5,
      designOne: design51,
      designTwo: design52,
      details:
        "Villa Alegría is a modern masterpiece designed around lush green courts and reflective water features. This single-story villa emphasizes spaciousness and light, with high ceilings, large glass panels, and shaded verandahs. Its crafted beige stone and wooden accents bring a sense of warmth and luxury, while the open courtyards create an idyllic setting for relaxation and entertainment.",
    },
    {
      name: "Qasr Al Yasmeen",
      imgelink: plot6,
      designOne: design61,
      designTwo: design62,
      details:
        "Qasr Al Yasmeen, “Palace of Jasmine,” is a seamless blend of modern architecture and natural elegance. The villa’s striking exterior showcases clean lines, beige stone facades, and vertical slatted designs that highlight its sophistication and timeless appeal. Expansive glass panels allow natural light to flood the interiors, creating a warm, welcoming ambiance while connecting the indoors to lush landscaped surroundings.",
    },
    {
      name: "Al Maha",
      imgelink: plot7,
      designOne: design71,
      designTwo: design72,
      details:
        "Nestled within a nature reserve, Al Maha embodies the harmony of desert living with luxurious comfort. The villa offers spectacular views of the vast natural landscape, with a focus on blending its interiors with the outdoors. With elements like a water fountain, infinity pool, and elevated terraces, it delivers a sense of calm and refinement that mirrors the grace of the Arabian Oryx.",
    },
    {
      name: "Qasr Al Waha",
      imgelink: plot8,
      designOne: design81,
      designTwo: design82,
      details:
        "Qasr Al Waha, “Palace of the Oasis,” is a testament to modern architectural brilliance paired with serene living. The villa is defined by its expansive layout, sleek stone facades, and seamless integration of indoor and outdoor spaces. With its grand double-height entrance, framed by vertical and horizontal lines, the home reflects a sense of sophistication and exclusivity.",
    },
  ];

  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formRef.current);

    emailjs
      .sendForm(
        "service_otx1rwi",
        "template_1wivaxh",
        formRef.current,
        "DsWeIlaGUWjO8AFSt"
      )
      .then((res) => {
        console.log(res.text);
        setLoading(false);
        const link = document.createElement("a");
        link.href = "/AHE-BROCHURE.pdf"; // Replace with your PDF path
        link.click();
      })
      .catch((err) => console.log(err));
  };

  const [showPopup, setShowPopup] = useState(false);

  const handleShow = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 60000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, []);

  return (
    <>
      {isPlotOpen && selectedPlot && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 font-custom">
          <button
            onClick={() => setIsPlotOpen(false)}
            className="absolute top-2 right-2 text-black bg-white rounded-full p-3 z-50"
          >
            <RxCross1 size="1.4em" />
          </button>
          <div className="relative w-full mx-6 max-w-screen-2xl h-max bg-white rounded-lg p-4 overflow-y-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-y-4 md:gap-4 ">
              <div className="flex flex-col justify-center items-center">
                {/* <img
                  className="w-full h-44 md:h-72 object-fill rounded-md"
                  src={selectedPlot.designOne}
                  alt="Design One"
                />
                <img
                  className="w-full h-44 mdh-72 object-fill rounded-md"
                  src={selectedPlot.designTwo}
                  alt="Design Two"
                /> */}
                <h3 className="z-10 mt-3 text-xs text-black font-custom5 font-extrabold py-4">
                  {selectedPlot.name}
                </h3>
                <p className="discription-pera text-center tracking-wider">
                  {selectedPlot.details}
                </p>
              </div>
              <div className="col-span-2">
                <img
                  className="w-full h-full object-cover"
                  src={selectedPlot.imgelink}
                  alt={selectedPlot.name}
                />
              </div>
            </div>
            {/* <p className="text-gray-700 text-base md:text-lg">
              {selectedPlot.details}
            </p> */}
          </div>
        </div>
      )}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-5 backdrop-filter backdrop-blur-sm opacity-900 blur-xs font-custom duration-300 ">
          <div className="bg-white relative rounded-lg shadow-lg flex flex-col md:flex-row mx-6 wind-flow w-[80vh]">
            <div className="w-full md:w-1/2 hidden md:block">
              <img
                src={popUp}
                className="w-full h-full object-cover rounded-t-lg md:rounded-tr-none md:rounded-l-lg saturate-150"
              />
            </div>

            <div className="w-full md:w-1/2 p-6">
              <svg
                className="w-6 h-6 absolute top-2 right-2 text-gray-700 cursor-pointer hover:scale-125 duration-300"
                onClick={() => setShowPopup(false)}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
              <h2 className="text-xl font-medium mb-4 text-black  text-center px-4 uppercase font-play-fair">
                Register Your Interest
              </h2>
              <form ref={formRef} onSubmit={sendEmail}>
                <div className="mb-4">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="your_name"
                    placeholder="Your Name"
                    required
                    className="w-full px-3 py-2 border rounded"
                  />
                </div>
                <div className="mb-4 ">
                  <label
                    className="block text-sm font-medium mb-1 "
                    htmlFor="phoneNumber"
                  >
                    Phone Number
                  </label>

                  <input
                    type="text"
                    name="your_number"
                    placeholder="Phone Number"
                    required
                    className="w-full px-3 py-2 border rounded"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-sm font-medium mb-1 "
                    htmlFor="email"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="your_email"
                    placeholder="Your Email"
                    required
                    className="w-full px-3 py-2 border rounded focus:border-primary"
                  />
                </div>

                <button
                  type="submit"
                  className="w-full block mx-auto rounded-full text-sm uppercase leading-6 text-primary border-2 tracking-widest hover:text-black transition duration-150 ease-in-out border-black py-2 px-4"
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
                <div className="pt-4">
                  <p className="text-xs font-medium text-gray-400 font-custom">
                    I agree to share my data with Deca properties, and allow
                    Deca properties or its affiliates to collect, control or
                    process my data in order to communicate with me.
                  </p>
                </div>
                {/* <div className="pt-4 text-center">
                  <a href={brochure} className="text-sm font-medium bg-black hover:bg-gray-800 duration-300 text-white p-2 rounded-lg">Download Brochure</a>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      )}
      {/* <LandingVideoComponent subtext="Phase 2: Just Announced" handleShow={handleShow} phaseTwo /> */}
      <div className="homehero-section-root" id="script">
        <Slide direction="up" duration={1400}>
          <div className="homehero-text-div">
            <h4 className="main-upper-laya-heading">
              Life Begins <br />
              at Arabian Hills Estate
            </h4>
            <h4 className="main-upper-heading font-custom mb-8 ">
              Phase 2: Just Announced
            </h4>

            <h1 className=" flex justify-center items-center">
              <img
                class="w-4/12 md:w-[12%] object-cover rounded-md"
                src={theConceptWhite}
              />
            </h1>

            <button
              className="learnMore-button text-base"
              onClick={() => handleShow()}
            >
              download brochure
            </button>
          </div>
        </Slide>
      </div>
      <div class="w-full font-custom1 mt-10 md:mt-16 mb-4">
        <h1 class=" text-black text-4xl text-center px-4 uppercase font-play-fair">
          Types of Plots in Arabian Hills Estate Phase 2
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 font-custom4">
        <div class="max-w-screen-xl mx-auto px-5 sm:px-10 md:px-16 pt-5 sm:pt-10 md:pt-16">
          <Slide direction="up" duration={1000}>
            <div class="rounded overflow-hidden flex flex-col max-w-xl mx-auto">
              <img class="w-full object-cover" src={villa} />
              <div class="relative -mt-16 px-10 pt-5 pb-16 bg-white m-10 ">
                <p class="font-medium text-lg inline-block transition duration-500 ease-in-out  mb-2 font-custom1">
                  Villa Plots
                </p>
                <p class="text-gray-500 text-sm tracking-wider font-custom">
                  <span className="font-bold font-custom1">Sizes: </span>12,000
                  – 20,000 sq. ft.
                </p>
                <p class="text-gray-500 text-sm tracking-wider font-custom">
                  <span className="font-bold font-custom1">Perfect For: </span>
                  Modern family homes with gardens and courtyards.
                </p>
              </div>
            </div>
          </Slide>
        </div>
        <div class="max-w-screen-xl mx-auto px-5 sm:px-10 md:px-16 pt-5 sm:pt-10 md:pt-16">
          <Slide direction="up" duration={1200}>
            <div class="rounded overflow-hidden flex flex-col max-w-xl mx-auto">
              <img class="w-full object-cover" src={mansion} />
              <div class="relative -mt-16 px-10 pt-5 pb-16 bg-white m-10 ">
                <p class="font-medium text-lg inline-block transition duration-500 ease-in-out  mb-2 font-custom1">
                  Mansion Plots
                </p>
                <p class="text-gray-500 text-sm tracking-wider font-custom">
                  <span className="font-bold font-custom1">Sizes: </span>20,000
                  – 50,000 sq. ft.
                </p>
                <p class="text-gray-500 text-sm tracking-wider font-custom">
                  <span className="font-bold font-custom1">Perfect For: </span>
                  Expansive residences with luxury features like private pools
                  and entertainment areas.
                </p>
              </div>
            </div>
          </Slide>
        </div>
        <div class="max-w-screen-xl mx-auto px-5 sm:px-10 md:px-16 pt-5 sm:pt-10 md:pt-16">
          <Slide direction="up" duration={1400}>
            <div class="rounded overflow-hidden flex flex-col max-w-xl mx-auto">
              <img class="w-full object-cover" src={megaMansion} />
              <div class="relative -mt-16 px-10 pt-5 pb-16 bg-white m-10 ">
                <p class="font-medium text-lg inline-block transition duration-500 ease-in-out  mb-2 font-custom1">
                  Mega Mansion Plots
                </p>
                <p class="text-gray-500 text-sm tracking-wider font-custom">
                  <span className="font-bold font-custom1">Sizes: </span>50,000
                  – 73,000 sq. ft. ft.
                </p>
                <p class="text-gray-500 text-sm tracking-wider font-custom">
                  <span className="font-bold font-custom1">Perfect For: </span>
                  Iconic, resort-style homes with unparalleled grandeur.
                </p>
              </div>
            </div>
          </Slide>
        </div>
      </div>
      <div class="max-w-screen mx-auto px-5 sm:px-10 md:px-16 ">
        <div class="mb-10  overflow-hidden flex flex-col md:flex-row mx-auto">
          <div className="w-full md:w-1/2 flex flex-col gap-4 justify-center py-5  pr-8">
            <Slide direction="left" duration={1400}>
              <div className="flex flex-col gap-4 text-lg tracking-wider ">
                <p className="discription-pera">
                  Arabian Hills Estate Phase 2 marks the newest chapter in this
                  prestigious UAE community, unveiling three clusters that
                  elevate bespoke living.
                </p>
                <p className="discription-pera">
                  Thoughtfully designed to complement their natural
                  surroundings, these clusters blend tranquility, connectivity,
                  and openness, offering the perfect canvas for luxurious,
                  individual expression.
                </p>
                {/* <p className="text-gray-700">
                  Each cluster has been carefully designed to reflect the
                  natural beauty of its surroundings while catering to the
                  unique aspirations of its future residents. Together, these
                  clusters represent a harmonious blend of tranquility,
                  connectivity, and openness, creating an environment where
                  luxury meets individuality.
                </p>
                <p className="text-gray-700">
                  Phase 2 continues the Arabian Hills vision of crafting
                  timeless communities, offering plots that inspire creativity
                  and deliver unmatched value.
                </p> */}
              </div>
            </Slide>
          </div>
          <div className="w-full md:w-1/2 ">
            <Slide direction="right" duration={1400}>
              <div>
                <img class=" object-cover " src={masterPlan} />
              </div>
            </Slide>
          </div>
        </div>
      </div>
      <div class="w-full font-custom4 mt-20 mb-16">
        <h1 class="text-black text-4xl text-center px-4 uppercase font-play-fair">
          New Clusters Launched in Arabian Hills Estate Phase 2
        </h1>
      </div>
      <div class="max-w-screen mx-auto px-5 sm:px-10 md:px-16 mb-8">
        <p className="discription-pera">
          Phase 2 unveils three new clusters—Nakheel Oasis, Park Vista, and Sun
          Valley—each with a unique identity and lifestyle.
        </p>
      </div>
      <div class="max-w-screen mx-auto px-5 sm:px-10 md:px-16 font-custom4">
        <div class="mb-4 md:mb-10  overflow-hidden flex flex-col md:flex-row mx-auto">
          <div className="w-full md:w-1/2 rounded-md">
            <Slide direction="left" duration={1400}>
              <div>
                <img class="object-cover " src={one} />
              </div>
            </Slide>
          </div>
          <div className="w-full md:w-1/2 flex flex-col gap-4 justify-center py-5  px-2 md:px-8">
            <Slide direction="right" duration={1400}>
              <div className="flex flex-col gap-4 text-lg tracking-wider ">
                <p className="text-black font-bold  text-xl text-center font-custom3">
                  Nakheel Oasis
                </p>
                <p className="discription-pera">
                  A serene retreat surrounded by native flora, Nakheel Oasis is
                  perfect for those seeking privacy and harmony with the desert.
                  Its tranquil ambiance and connection to nature make it a haven
                  of peace and luxury.
                </p>
              </div>
            </Slide>
          </div>
        </div>
      </div>

      <div class="max-w-screen mx-auto px-5 sm:px-10 md:px-16 font-custom4">
        <div class="mb-4 md:mb-10 overflow-hidden flex flex-col md:flex-row mx-auto">
          <div className="w-full md:w-1/2   flex flex-col gap-4 justify-center py-5  px-2 md:px-8 order-2 md:order-none">
            <Slide direction="left" duration={1400}>
              <div className="flex flex-col justify-center gap-4 text-lg tracking-wider ">
                <p className="text-black font-bold  text-xl text-center font-custom3">
                  Park Vista
                </p>
                <p className="discription-pera">
                  Blending lush green landscapes with vibrant pathways, Park
                  Vista is ideal for those who value connectivity and balance.
                  This cluster offers a perfect mix of open spaces and
                  well-designed pathways, fostering an active yet serene
                  lifestyle.
                </p>
              </div>
            </Slide>
          </div>
          <div className="w-full md:w-1/2">
            <Slide direction="right" duration={1400}>
              <div>
                <img class=" object-cover" src={two} />
              </div>
            </Slide>
          </div>
        </div>
      </div>
      <div class="max-w-screen mx-auto px-5 sm:px-10 md:px-16 font-custom4">
        <div class="mb-8 md:mb-10  overflow-hidden flex flex-col md:flex-row mx-auto">
          <div className="w-full md:w-1/2">
            <Slide direction="left" duration={1400}>
              <div>
                <img class=" object-cover" src={three} />
              </div>
            </Slide>
          </div>
          <div className="w-full md:w-1/2 flex flex-col gap-4 justify-center py-5  px-2 md:px-8">
            <Slide direction="right" duration={1400}>
              <div className="flex flex-col gap-4 text-lg tracking-wider  ">
                <p className="text-black font-bold  text-xl text-center font-custom3">
                  Sun Valley
                </p>
                <p className="discription-pera">
                  Celebrating open landscapes and natural light, Sun Valley is
                  designed to create warm, inviting spaces. This cluster is
                  perfect for families or individuals looking to embrace
                  expansive views and a sense of openness in their homes.
                </p>
                {/* <p className="text-gray-700">
                  Together, these clusters form the heart of Phase 2, a
                  continuation of the Arabian Hills vision of luxurious living.
                  Each cluster is crafted to inspire, adapt, and elevate every
                  lifestyle.
                </p> */}
              </div>
            </Slide>
          </div>
        </div>
      </div>
      <div class="w-full font-custom4 mt-10 md:mt-20 mb-16 flex justify-center items-center gap-2">
        <h1 class="text-black text-4xl text-center px-4 uppercase font-play-fair">
          Introducing "The Concept" – A First in Bespoke Living
        </h1>
        {/* <img
          class="w-4/12 md:w-2/12 object-cover rounded-md"
          src={theConcept}
          alt="Sunset in the mountains"
        /> */}
      </div>
      <div class="max-w-screen mx-auto px-5 sm:px-10 md:px-16 ">
        <div class="mb-10  overflow-hidden flex flex-col md:flex-row mx-auto">
          <div className="w-full md:w-1/2 flex flex-col gap-4 justify-center py-5  pr-8">
            <Slide direction="left" duration={1400}>
              <div className="flex flex-col gap-4 text-lg tracking-wider ">
                <p className="discription-pera">
                  For the first time in the UAE, "The Concept" offers you the
                  freedom to design your dream home on your own land. Start with
                  a premium plot in the prestigious Arabian Hills Estate, and
                  then choose from ten expertly crafted villa and mansion
                  designs—each fully customizable to reflect your style and
                  needs
                </p>
                <p className="discription-pera">
                  Unlike traditional neighborhoods, no two homes in Arabian
                  Hills Estate will look the same. Your residence will be as
                  unique as your vision, ensuring it stands apart from the rest.
                  Say goodbye to identical homes and hello to a one-of-a-kind
                  living experience.
                </p>
                <p className="discription-pera">
                  A groundbreaking opportunity in luxury and individuality. Your
                  land. Your vision. Your legacy. Welcome to "The Concept."
                </p>
                {/* <p className="text-gray-700">
                  Each cluster has been carefully designed to reflect the
                  natural beauty of its surroundings while catering to the
                  unique aspirations of its future residents. Together, these
                  clusters represent a harmonious blend of tranquility,
                  connectivity, and openness, creating an environment where
                  luxury meets individuality.
                </p>
                <p className="text-gray-700">
                  Phase 2 continues the Arabian Hills vision of crafting
                  timeless communities, offering plots that inspire creativity
                  and deliver unmatched value.
                </p> */}
              </div>
            </Slide>
          </div>
          <div className="w-full md:w-1/2 ">
            <Slide direction="right" duration={1400}>
              <div>
                <img class=" object-cover " src={villaDesign} />
              </div>
            </Slide>
          </div>
        </div>
      </div>

      <div class="max-w-screen mx-auto px-5 sm:px-10 md:px-16 mt-4">
        <div className="w-full flex flex-col gap-4  pb-12 text-lg tracking-wider justify-center">
          {/* <p className="discription-pera">
            At the core of Phase 2 lies The Concept, a transformative approach
            that streamlines the journey to your dream home. With the purchase
            of a plot, you gain access to an exclusive collection of expertly
            designed villa blueprints tailored to suit the size and character of
            your land.
          </p>
          <p className="discription-pera">
            These pre-designed packages include everything needed to fast-track
            construction: detailed schematics, MEP (mechanical, electrical, and
            plumbing) plans, BoQ (bill of quantities), and pre-approved
            drawings. The Concept not only simplifies the building process but
            also saves time and reduces costs, offering a seamless path to a
            bespoke residence while maintaining flexibility for your own
            creative vision.
          </p> */}
          <p className="text-black text-2xl uppercase font-play-fair">
            EVERY VILLA, A UNIQUE STORY
          </p>
          <p className="discription-pera">
            At Arabian Hills Estate, "The Concept" brings individuality,
            elegance, and harmony to life with exclusive villa designs. Each is
            crafted to inspire bespoke luxury living. Each villa is unique,
            making Arabian Hills a community of distinction. Your home is your
            story, your legacy.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 md:gap-12 max-w-screen mx-auto px-5 sm:px-10 md:px-16  font-custom4">
        {PlotData.map((plot, index) => (
          <Slide key={index} direction="up" duration={1000 + index * 50}>
            <div
              className="cursor-pointer"
              onClick={() => handleCardClick(plot)}
            >
              <div className="w-full relative isolate flex flex-col justify-end overflow-hidden  px-8 pb-8 pt-40">
                <img
                  src={plot.imgelink}
                  alt={plot.name}
                  className="absolute inset-0 h-full w-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40 opacity-40"></div>
                <h3 className="z-10 mt-3 text-xs text-white font-custom5">
                  {plot.name}
                </h3>
              </div>
            </div>
          </Slide>
        ))}
      </div>
      <div class="w-full font-custom1 my-16 px-4">
        <h1 class="text-black text-4xl text-center px-4 uppercase font-play-fair">
          5 Year Payment Plan
        </h1>
      </div>
      <div class="max-w-screen mx-auto px-5 sm:px-10 md:px-16 mb6">
        <p className="discription-pera">
          Be part of Arabian Hills Estate with a flexible 5-year payment plan: 3
          years pre-handover + 2 years post-handover installments, making
          ownership accessible and manageable.
        </p>
      </div>

      <div className=" py-6">
        <div className="flex flex-col grid-cols-9 p-2 pr-4 mx-auto md:grid gap-x-12">
          <div className="flex md:contents flex-row-reverse">
            <div className="relative p-4 my-6 text-gray-800 bg-gray-200 rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto w-max">
              <h3 className="text-lg font-medium lg:text-xl font-play-fair uppercase pb-3 ">
                Initial Payment
              </h3>
              <span className="discription-pera">10% upon booking</span>
            </div>
            <div className="relative col-start-5 col-end-6 mr-4 md:mx-auto">
              <div className="flex items-center justify-center w-6 h-full">
                <div className="w-1 h-full bg-gray-400 rounded-t-full bg-gradient-to-b from-gray-400 to-gray-800"></div>
              </div>
              <div className="absolute w-6 h-6 -mt-3 bg-white border-4 border-gray-800 rounded-full top-1/2"></div>
            </div>
          </div>

          <div className="flex md:contents">
            <div className="relative col-start-5 col-end-6 mr-4 md:mx-auto">
              <div className="flex items-center justify-center w-6 h-full">
                <div className="w-1 h-full bg-gray-400 rounded-t-full bg-gradient-to-b from-gray-400 to-gray-800"></div>
              </div>
              <div className="absolute w-6 h-6 -mt-3 bg-white border-4 border-gray-800 rounded-full top-1/2"></div>
            </div>
            <div className="relative p-4 my-6 text-gray-800 bg-gray-200 rounded-xl col-start-6 col-end-10 mr-auto w-max">
              <h3 className="text-lg font-medium lg:text-xl font-play-fair uppercase pb-3 ">
                Monthly Instalments
              </h3>
              <span className="discription-pera">
                1% for the next 35 months
              </span>
            </div>
          </div>
          <div className="flex md:contents flex-row-reverse">
            <div className="relative p-4 my-6 text-gray-800 bg-gray-200 rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto w-max">
              <h3 className="text-lg font-medium lg:text-xl font-play-fair uppercase pb-3 ">
                Milestone Payment
              </h3>
              <span className="discription-pera">5% in the 12th month</span>
            </div>
            <div className="relative col-start-5 col-end-6 mr-4 md:mx-auto">
              <div className="flex items-center justify-center w-6 h-full">
                <div className="w-1 h-full bg-gray-400 rounded-t-full bg-gradient-to-b from-gray-400 to-gray-800"></div>
              </div>
              <div className="absolute w-6 h-6 -mt-3 bg-white border-4 border-gray-800 rounded-full top-1/2"></div>
            </div>
          </div>
          <div className="flex md:contents">
            <div className="relative col-start-5 col-end-6 mr-4 md:mx-auto">
              <div className="flex items-center justify-center w-6 h-full">
                <div className="w-1 h-full bg-gray-400 rounded-t-full bg-gradient-to-b from-gray-400 to-gray-800"></div>
              </div>
              <div className="absolute w-6 h-6 -mt-3 bg-white border-4 border-gray-800 rounded-full top-1/2"></div>
            </div>
            <div className="relative p-4 my-6 text-gray-800 bg-gray-200 rounded-xl col-start-6 col-end-10 mr-auto w-max">
              <h3 className="text-lg font-medium lg:text-xl font-play-fair uppercase pb-3 ">
                Post Handover Installments
              </h3>
              <span className="discription-pera ">
                6.25% quarterly over 2 years
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full font-custom1 my-16 px-4">
        <h1 class="text-black text-4xl text-center px-4 uppercase font-play-fair">
          Arabian Hills Estate Phase 2 Gallery
        </h1>
      </div>
      <Gallery data={data} />
    </>
  );
};

export default PhaseTwoComponent;
